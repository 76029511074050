<template>
    <div class="user-availability-manager">
        <div class="topbar">
            <!-- <div class="left">
                <span>Week:</span> <a-date-picker v-model="selectedDate" />
            </div> -->
            <div class="right">
                <a-button class="edit-rota-button" @click.prevent="editRota">Edit Rota</a-button>
                <!-- <a-button @click.prevent="editExceptions">Edit Exceptions</a-button> -->
                <edit-exceptions-button-and-modal
                    :tenant-id="tenantId"
                    :org="org"
                    :user="user"
                    @updated="fetch"
                ></edit-exceptions-button-and-modal>
            </div>
        </div>

        <loading-screen :is-loading="isLoading"></loading-screen>

        <div class="calendar">
            <vue-cal :selected-date="selectedDate" :disable-views="['years', 'year', 'month', 'day']" :events="events"
                @ready="setStartDate" @view-change="setStartDate" :hide-view-selector="true" :hide-weekends="true"
                style="height: 500px" />
        </div>

        <!-- <div>
            Schedules: <br>
            {{ schedules }}
        </div>

        <div>
            Overrides: <br>
            {{ overrides }}
        </div>

        <div>
            Timetable week number: <br>
            {{ timetableWeekNumber }}
        </div>

        <div>
            Calendar events: <br>
            {{ events }}
        </div> -->
    </div>
</template>

<script>
const _ = require('lodash');
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import availability from '../../../../../api/availability'
import { mapGetters } from 'vuex'
import LoadingScreen from '../../../../../components/LoadingScreen.vue';
import rota from "../../../../../helpers/rota";
import EditExceptionsButtonAndModal from './UserAvailabilityManager/EditExceptionsButtonAndModal.vue';

const MONDAY = 1;
const TUESDAY = 2;
const WEDNESDAY = 4;
const THURSDAY = 8;
const FRIDAY = 16;
// const SATURDAY = 32;
// const SUNDAY = 64;

export default {
    props: ['user', 'org', 'tenantId'],
    components: { VueCal, LoadingScreen, EditExceptionsButtonAndModal },
    data() {
        return {
            selectedDate: new Date(),
            startDate: new Date(),

            isLoadingSchedules: false,
            schedules: [],

            isLoadingOverrides: false,
            overrides: [],

            isLoadingTimetableWeekNumber: false,
            timetableWeekNumber: null
        }
    },
    computed: {
        ...mapGetters('admin', {
            timezone: 'timezone'
        }),

        isLoading() {
            return (this.isLoadingSchedules || this.isLoadingOverrides || this.isLoadingTimetableWeekNumber);
        },

        days() {
            if (!this.startDate) {
                return [];
            }

            let days = [];

            let startDate = window.moment(this.startDate);
            _.each([0, 1, 2, 3, 4, 5, 6], i => {
                days.push(
                    startDate.clone().add(i, 'days')
                );
            });

            return days;
        },

        schedulesForCurrentWeek() {
            if (this.timetableWeekNumber == null) {
                return [];
            }

            return _.filter(this.schedules, schedule => {
                return schedule.selectedWeeks.includes(this.timetableWeekNumber);
            });
        },

        events() {
            if (this.isLoading) {
                return [];
            }
            let events = [];

            // Availability
            let daysOfWeek = rota.listDayOptions();
            _.each(daysOfWeek, dayOfWeek => {
                _.each(this.schedules, schedule => {
                    if (rota.isDaySelected(schedule.selectedDays, dayOfWeek.value)) {
                        _.each(schedule.periods, period => {
                            events.push({
                                start: this.buildInstantFromSchedule(dayOfWeek.value, period.startTime),
                                end: this.buildInstantFromSchedule(dayOfWeek.value, period.endTime),
                                title: 'Schedule - Available',
                                class: 'available'
                            });
                        });
                    }
                });
            });

            // Overrides
            _.each(this.overrides, override => {
                events.push({
                    start: window.moment(override.startInstant).format('YYYY-MM-DD HH:mm'),
                    end: window.moment(override.endInstant).format('YYYY-MM-DD HH:mm'),
                    // start: override.startInstant,
                    // end: override.endInstant,
                    title: override.available ? 'Override - Available' : 'Override - Unavailable',
                    class: override.available ? 'available' : 'unavailable'
                });
            });

            return events;
        }
    },
    watch: {
        startDate() {
            this.fetch();
        }
    },
    methods: {
        setStartDate(e) {
            this.startDate = e.startDate;
        },

        editRota() {
            this.$router.push(
                "/admin/responders/availability/" + this.user.id + "/rota"
            );
        },

        editExceptions() {
            this.$router.push(
                "/admin/responders/availability/" + this.user.id + "/exceptions"
            );
        },

        buildInstantFromSchedule(dayOfWeekValue, time) {
            let baseDate = window.moment(this.startDate);

            if (dayOfWeekValue == MONDAY) {
                baseDate = baseDate.add(0, 'days');
            }
            if (dayOfWeekValue == TUESDAY) {
                baseDate = baseDate.add(1, 'days');
            }
            if (dayOfWeekValue == WEDNESDAY) {
                baseDate = baseDate.add(2, 'days');
            }
            if (dayOfWeekValue == THURSDAY) {
                baseDate = baseDate.add(3, 'days');
            }
            if (dayOfWeekValue == FRIDAY) {
                baseDate = baseDate.add(4, 'days');
            }

            let timeParts = time.split(':');

            // baseDate = baseDate.set('hour', timeParts[0]);
            // baseDate = baseDate.set('minute', timeParts[1]);

            return baseDate.format('YYYY-MM-DD') + ' ' + timeParts[0] + ':' + timeParts[1];
        },

        async fetch() {
            let vm = this;

            vm.schedules = [];
            vm.overrides = [];

            vm.isLoadingOverrides = true;
            vm.isLoadingSchedules = true;
            vm.isLoadingTimetableWeekNumber = true;

            vm.schedules = await availability.getSchedulesForPresence(this.tenantId, this.user.id).then(r => r.data).catch(() => []);
            vm.isLoadingSchedules = false;

            vm.overrides = await availability.getScheduleOverridesForPresence(this.tenantId, this.user.id).then(r => r.data).catch(() => []);
            vm.isLoadingOverrides = false;

            let startDateMoment = window.moment(this.startDate).endOf('day');
            let zonedDateTime = startDateMoment.format() + ' ' + this.timezone;
            vm.timetableWeekNumber = await availability.getTimetableWeekNumberForOrg(this.tenantId, this.org.id, zonedDateTime).then(r => r.data).catch(() => null);
            vm.isLoadingTimetableWeekNumber = false;
        }
    }
}
</script>

<style lang="scss">
.user-availability-manager {
    .calendar {
        background: #fff;

        .vuecal__event-title {
            font-weight: 600;
        }

        .available {
            // background: #99c140;
            // color: #fff;
            background: #f0f6ff;
            color: #689bee;
        }

        .unavailable {
            // background: hsl(27deg, 100%, 97%) repeating-linear-gradient(-45deg, hsla(27deg, 100%, 67%, .25), hsla(27deg, 100%, 67%, .25) 5px, rgba(255, 255, 255, 0) 5px, rgba(255, 255, 255, 0) 15px);
            background: rgba(255, 114, 118, 0.9);
            // color: #f6984c;
            color: #fff;
        }
    }

    .topbar {
        display: flex;
        margin-bottom: 20px;

        .left {
            flex-grow: 1;

            span {
                margin-right: 10px;
            }
        }

        .right {
            flex-shrink: 1;

            .edit-rota-button {
                margin-right: 10px;
            }
        }
    }
}
</style>