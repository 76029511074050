<template>
  <div class="user-details">
    <div class="card">
      <!-- <h3>Basic Details</h3> -->
      <a-descriptions :column="2" bordered>
        <a-descriptions-item label="First Name">
          {{ user.firstname }}
        </a-descriptions-item>
        <a-descriptions-item label="Surname">
          {{ user.surname }}
        </a-descriptions-item>
        <a-descriptions-item label="Display Name">
          {{ user.displayName }}
        </a-descriptions-item>
        <a-descriptions-item label="Email">
          {{ user.email }}
        </a-descriptions-item>
        <a-descriptions-item label="Alternative Email (Where Differs to Username)">
          {{ user.altEmail ? user.altEmail : "-" }}
        </a-descriptions-item>
        <a-descriptions-item label="Photo">
          <presence-thumbnail :presence-id="user.id" :owner-id="org.id" size="small"></presence-thumbnail>
        </a-descriptions-item>
        <a-descriptions-item label="Is Responder?">
          <a-icon style="font-size: 17px;" v-if="user.isResponder" theme="twoTone" two-tone-color="#52c41a" type="check-circle" />
          <a-icon style="font-size: 17px;" v-else theme="twoTone" two-tone-color="#ff4d4f" type="close-circle" />
        </a-descriptions-item>
        <a-descriptions-item label="Role">
          {{ userType }}
        </a-descriptions-item>
        <a-descriptions-item label="Expires At">
          {{ user.deprecatedInstant ? formatDeprecatedInstant(user.deprecatedInstant) : "-" }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script>
import PresenceThumbnail from "../../../../../components/Presences/PresenceThumbnail";
import userHelpers from "../../../../../helpers/users";
export default {
  props: ["user", "org", "tenantId"],
  components: { PresenceThumbnail },
  computed: {
    userType() {
      return userHelpers.getUserPermissionDescription(this.user);
    }
  },
  methods: {
    formatDeprecatedInstant() {
      if (this.user.deprecatedInstant) {
        return window.moment(this.user.deprecatedInstant).format("dddd Do MMMM Y, HH:mm");
      }
      return this.user.deprecatedInstant;
    }
  }
};
</script>

<style lang="scss">
.user-details {
  .card {
    // background: #fff;
    // padding: 20px;
    // border-radius: 6px;

    h3 {
      margin-bottom: 19px;
    }
  }

  .ant-descriptions {
    background: #fff;
  }

  .ant-descriptions-item-label {
    font-weight: 500;
  }
}
</style>