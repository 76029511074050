<template>
  <div>
    <a-button type="primary" icon="plus" @click.prevent="openModal"
      >Add Response Team</a-button
    >

    <a-modal
      centered
      v-model="modalVisible"
      title="Add Response Team"
      :footer="null"
    >
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>

      <!-- Response teams -->
      <div v-if="!isLoading">
        <!-- No response teams -->
        <a-alert
          v-if="responseTeamsToShow.length === 0"
          type="error"
          message="There are no response teams that can be added"
        />
        <!-- / No response teams -->

        <div class="response-teams-wrapper" v-if="responseTeamsToShow.length">
          <div
            @click.prevent="() => addResponseTeam(responseTeam)"
            class="response-team-list-item"
            v-for="responseTeam in responseTeamsToShow"
            :key="responseTeam.id"
          >
            {{ responseTeam.displayName }}
          </div>
        </div>
      </div>
      <!-- / Response teams -->
    </a-modal>
  </div>
</template>

<script>
const _ = require('lodash');
import organisations from ".././../../../../../api/organisations";
export default {
  props: ["currentResponseTeams", "tenantId", "org"],
  data() {
    return {
      isLoading: false,
      modalVisible: false,
      allResponseTeams: [],
    };
  },
  watch: {
    modalVisible(isVisible) {
      if (isVisible) {
        this.fetch();
      }
    },
  },
  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      vm.allResponseTeams = [];
      organisations
        .getSkillsForOrg(this.tenantId, this.org.id)
        .then((r) => {
          vm.isLoading = false;
          vm.allResponseTeams = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
        });
    },

    addResponseTeam(responseTeam) {
      // let vm = this;
      this.$emit("add-response-team", responseTeam);
      this.closeModal();
    },

    openModal() {
      this.modalVisible = true;
    },

    closeModal() {
      this.modalVisible = false;
    },
  },
  computed: {
    responseTeamsToShow() {
      let selectedIds = _.map(this.currentResponseTeams, "id");
      return _.filter(this.allResponseTeams, (team) => {
        return !selectedIds.includes(team.id);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.response-team-list-item {
  padding: 15px 20px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #eee;

  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0 !important;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}
</style>