<template>
  <div class="user-devices">
   
   <!-- Loading -->
   <div class="loader" v-if="isLoadingCombined">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->
    
     <!-- Devices - Nothing to show -->
     <div v-if="!isLoadingCombined && !devices.length">
      <a-alert type="error" message="There are no devices to show" />
    </div>
    <!-- / Devices - Nothing to show -->

    <!-- Devices -->
    <div v-if="!isLoadingCombined && devices.length">
      <!-- {{  devices }} -->
      <a-table
        :key="updateKey"
        :columns="columns"
        :data-source="devicesForTable"
        :pagination="false"
        bordered
      ></a-table>
    </div>
    <!-- / Devices -->

  </div>
</template>

<script>
const _ = require('lodash');
import pushRegistrations from "../../../../../api/push-registrations";
import moment from 'moment';

// const PLATFORMS = [
//   'None',
//   'Firebase',
//   'Apns',
//   'Wns'
// ];

const PLATFORMS = [
  'None',
  'Android',
  'Apple',
  'Windows'
];

export default {
  props: ['user', 'org', 'tenantId'],

  data() {
    return {
      isLoading: false,
      devices: [],
      updateKey: 1,
      columns: [
        {
          key: "platform",
          title: "Platform",
          dataIndex: "platformHuman"
        },
        {
          key: "os",
          title: "OS",
          dataIndex: "osVersion"
        },
        {
          key: "bundle",
          title: "Bundle",
          dataIndex: "bundleId"
        },
        {
          key: "app",
          title: "App",
          dataIndex: "appVersion"
        },
        {
          key: "lastSeen",
          title: "Last Seen",
          dataIndex: "lastSeenAtHuman"
        }
      ],
    }
  },

  created() {
    this.loadDevices();
  },

  methods: {
    loadDevices() {
      let vm = this;
      vm.devices = [];
      vm.isLoading = true;
      pushRegistrations.getPushRegistrationsForSubject(
        this.tenantId, this.org.id, this.user.subjectId
      ).then(r => {
        vm.isLoading = false;
        vm.devices = r.data;
      }).catch(e => {
        console.log(e);
        vm.$message.error('Error loading devices for user');
        vm.isLoading = false;
      })
    }
  },

  computed: {
    isLoadingCombined() {
      return this.isLoading
    },

    devicesForTable() {
      return _.map(this.devices, device => {
        return {
          platformHuman: PLATFORMS[device.pushPlatform],
          lastSeenAtHuman: moment(device.lastSeenAt).isValid ? moment(device.lastSeenAt).fromNow() : device.lastSeenAt,
          ...device
        };
      });
    }
  }
}
</script>

<style lang="scss">
.user-devices {
  .loader {
    text-align: center;
    padding: 40px;
  }

  .ant-table {
    background: #fff !important;
  }
}
</style>