<template>
  <div class="user-response-teams">
    <loading-screen :is-loading="isSaving || isDeleting"></loading-screen>

    <!-- Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Response Teams - Nothing To Show -->
    <div class="nothing-to-show" v-if="!isLoading && !responseTeams.length">
      <a-alert type="error" message="User not assigned to any response teams" />
    </div>
    <!-- / Response Teams - Nothing To Show -->

    <!-- Response Teams -->
    <div class="response-teams" v-if="!isLoading && responseTeams.length">
      <response-team-card
        v-for="(responseTeam, i) in responseTeams"
        :tenant-id="tenantId"
        :org-id="orgId"
        :user="user"
        :response-team="responseTeam"
        :key="i"
        @remove="() => removeSkill(responseTeam)"
      ></response-team-card>
    </div>
    <!-- / Response Teams -->

    <!-- Add to response team -->
    <add-to-response-team-button-and-modal
      v-if="!isLoading"
      :current-response-teams="responseTeams"
      :tenant-id="tenantId"
      :org="org"
      @add-response-team="addResponseTeam"
    ></add-to-response-team-button-and-modal>
    <!-- / Add to response team -->
  </div>
</template>

<script>
import presences from "../../../../../api/presences";
import ResponseTeamCard from "./ResponseTeams/ResponseTeamCard.vue";
import LoadingScreen from "./../../../../../components/LoadingScreen.vue";
import AddToResponseTeamButtonAndModal from "./ResponseTeams/AddToResponseTeamButtonAndModal.vue";
export default {
  props: ["user", "org", "tenantId"],
  data() {
    return {
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      responseTeams: [],
    };
  },
  components: { ResponseTeamCard, LoadingScreen, AddToResponseTeamButtonAndModal },
  created() {
    this.loadResponseTeams();
  },
  methods: {
    loadResponseTeams() {
      let vm = this;
      vm.responseTeams = [];
      vm.isLoading = true;
      presences
        .getSkillsForPresence(this.tenantId, this.user.id)
        .then((r) => {
          vm.responseTeams = r.data;
          vm.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading response teams");
        });
    },

    removeSkill(responseTeam) {
      let vm = this;
      vm.isDeleting = true;
      presences
        .deleteSkillAssignment(
          this.tenantId,
          this.org.id,
          responseTeam.id,
          this.user.id
        )
        .then(() => {
          vm.$message.success("Response team removed successfully");
          vm.isDeleting = false;
          vm.loadResponseTeams();
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error removing response team");
          vm.isDeleting = false;
        });
    },

    addResponseTeam(responseTeam) {
      let vm = this;
      vm.isSaving = true;
      presences.addSkillAssignment(
        this.tenantId,
        this.org.id,
        responseTeam.id,
        this.user.id
      ).then(() => {
        vm.$message.success('Response team added successfully');
        vm.isSaving = false;
        vm.loadResponseTeams();
      }).catch(e => {
        console.log(e);
        vm.isSaving = false;
        vm.$message.error('Error adding response team');
      })
    }
  },
};
</script>

<style scoped lang="scss">
.user-response-teams {
  .loader {
    text-align: center;
    padding: 40px;
  }

  .response-team-card, .nothing-to-show {
    margin-bottom: 15px;
  }
}
</style>