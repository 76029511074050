<template>
  <div class="response-team-card">
    <div class="flex">
      <div class="left">
        {{ responseTeam.displayName }}
      </div>
      <div class="right">
        <a-popconfirm
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="$emit('remove')"
        >
          <a-button type="danger" icon="delete">Remove</a-button>
        </a-popconfirm>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["tenantId", "orgId", "user", "responseTeam"],
  methods: {},
};
</script>

<style scoped lang="scss">
.response-team-card {
  padding: 20px 25px;
  border-radius: 6px;
  background: #fff;

  .flex {
    display: flex;

    .left {
      flex-grow: 1;
      font-weight: 500;
      display: flex;
      align-items: center;
      align-content: center;
    }

    .right {
      flex-shrink: 1;
    }
  }
}
</style>