<template>
  <div>
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <a-table  :columns="columns" :data-source="exceptionsForTable" :pagination="false" bordered>
      <div slot="actions" slot-scope="text, record">
        <a-popconfirm title="Are you sure?" ok-text="Yes" cancel-text="No" @confirm="deleteException(record)">
          <a-button type="danger" icon="delete">Delete</a-button>
        </a-popconfirm>
      </div>
    </a-table>
  </div>
</template>

<script>
const _ = require('lodash');
import availability from '../../../../../../../api/availability';
import LoadingScreen from '../../../../../../../components/LoadingScreen.vue';

const COLUMNS = [
  {
    title: "Status",
    dataIndex: "statusHuman",
  },
  {
    title: "From",
    dataIndex: "fromHuman",
  },
  {
    title: "Until",
    dataIndex: "untilHuman",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    scopedSlots: { customRender: "actions" },
    width: 100,
  },
];

export default {
  props: ['tenantId', 'org', 'user'],

  components: {LoadingScreen},

  data() {
    return {
      isLoadingExceptions: false,
      exceptions: [],
      columns: COLUMNS,

      isDeleting: false
    }
  },

  created() {
    this.fetch();
  },

  computed: {
    exceptionsForTable() {
      return _.map(this.exceptions, (exception) => {
        let statusHuman = "Unavailable";
        if (exception.available) {
          statusHuman = "Available";
        }

        let fromHuman = window
          .moment(exception.startInstant)
          .format("ddd Do YYYY, HH:mm");
        let untilHuman = window
          .moment(exception.endInstant)
          .format("ddd Do YYYY, HH:mm");

        return {
          statusHuman,
          fromHuman,
          untilHuman,
          ...exception,
        };
      });
    },
  },

  methods: {
    handleUpdated() {
      this.$emit('updated');
    },

    fetch() {
      let vm = this;
      vm.exceptions = [];
      vm.isLoadingExceptions = true;
      availability
        .getScheduleOverridesForPresence(this.tenantId, this.user.id)
        .then(r => {
          vm.exceptions = r.data;
          vm.isLoadingExceptions = false;
        }).catch(e => {
          console.log(e);
          vm.isLoadingExceptions = false;
          vm.$message.error('Error loading exceptions');
        });
    },

    deleteException(exception) {
      let vm = this;
      vm.isDeleting = true;
      availability
        .deleteOverride(this.tenantId, exception.id)
        .then(() => {
          vm.isDeleting = false;
          vm.fetch();
          vm.handleUpdated();
        }).catch(e => {
          console.log(e);
          vm.isDeleting = false;
          vm.$message.error('Error deleting exception');
        });
    }
  }
}
</script>

<style></style>