<template>
  <div class="edit-exceptions-button-and-modal">
    <a-button class="edit-rota-button" @click.prevent="openModal">Edit Exceptions</a-button>

    <a-modal v-model="showModal" title="Edit Exceptions" :footer="null" :width="900">
      <div v-if="showModal">
        <exceptions-table
          :tenant-id="tenantId"
          :org="org"
          :user="user"
          :key="exceptionsTableKey"
          @updated="$emit('updated')"
        ></exceptions-table>

        <div style="padding-top: 20px">
          <add-exception-button-and-modal :tenant-id="tenantId"
          @updated="handleExceptionAdded"
          :org="org" :user="user"
          ></add-exception-button-and-modal>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import AddExceptionButtonAndModal from './EditExceptionsButtonAndModal/AddExceptionButtonAndModal.vue';
import ExceptionsTable from './EditExceptionsButtonAndModal/ExceptionsTable.vue';
export default {
  components: { ExceptionsTable, AddExceptionButtonAndModal },
  props: ['tenantId', 'org', 'user'],

  data() {
    return {
      showModal: false,
      exceptionsTableKey: 1
    }
  },

  methods: {
    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

    handleExceptionAdded() {
      this.exceptionsTableKey += 1;
      this.$emit('updated');
    }
  }
}
</script>

<style scoped lang="scss">
.edit-exceptions-button-and-modal {
  display: inline-block;
}
</style>