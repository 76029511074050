<template>
  <div class="user-content-views">
    <!-- Loading -->
    <div class="loader" v-if="isLoadingCombined">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Content Views - Nothing to show -->
    <div v-if="!isLoadingCombined && !contentViews.length">
      <a-alert type="error" message="There are no content views to show" />
    </div>
    <!-- / Content Views - Nothing to show -->

    <!-- Content Views -->
    <div v-if="!isLoadingCombined && contentViews.length">
      <a-table
        :key="updateKey"
        :columns="columns"
        :data-source="contentViewsForTable"
        :pagination="false"
        bordered
      >
      </a-table>
    </div>
    <!-- / Content Views -->
  </div>
</template>

<script>
import presences from "../../../../../api/presences";
import helpVideos from "../../../../../api/guidance/help-videos";
import articles from "../../../../../api/guidance/articles";
import topics from "../../../../../api/guidance/topics";
import policies from "../../../../../api/guidance/policies";
import moment from "moment";
import contentLinkHelpers from "../../../../../helpers/content-links";
import externalLinks from "../../../../../api/guidance/external-links";
const _ = require("lodash");
export default {
  name: "ContentViews",
  props: ["user", "org", "tenantId"],
  data() {
    return {
      isLoading: false,
      isLoadingNameForGuids: [],
      contentViews: [],
      updateKey: 1,
      columns: [
        // {
        //   title: "Viewed Entity ID",
        //   dataIndex: "viewedEntityId",
        // },
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Type",
          dataIndex: "type",
        },
        {
          title: "Viewed At",
          dataIndex: "viewedAtHuman",
        },
        {
          title: "View Source",
          dataIndex: "viewSourceHuman",
        },
      ],
      names: {},
    };
  },
  created() {
    this.loadContentViews();
  },
  methods: {
    loadContentViews() {
      let vm = this;
      vm.contentViews = [];
      vm.isLoading = true;
      presences
        .getLastViewsForPresence(this.tenantId, this.org.id, this.user.id)
        .then((r) => {
          vm.isLoading = false;
          vm.contentViews = r.data;
          vm.loadNames();
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error loading content views");
          vm.isLoading = false;
        });
    },

    loadNames() {
      let vm = this;
      vm.names = {};
      vm.contentViews.forEach((cv) => {
        vm.loadNameForEntity(vm, cv.viewedEntityId, cv.viewedEntityType);
      });
    },

    stopLoadingNameForGuid(guid) {
      this.isLoadingNameForGuids = _.without(this.isLoadingNameForGuids, guid);
    },

    loadNameForEntity(vm, guid, type) {
      vm.isLoadingNameForGuids.push(guid);
      let typeHuman = _.find(this.entityViewObjectTypes, { value: type }).type;
      if (typeHuman == "Unknown") {
        vm.names[guid] = "Unknown";
        vm.stopLoadingNameForGuid(guid);
      } else if (typeHuman == "Help Video") {
        helpVideos
          .getHelpVideo(this.tenantId, guid)
          .then((r) => {
            vm.names[guid] = r.data["$v"].displayName;
            vm.stopLoadingNameForGuid(guid);
          })
          .catch((e) => {
            console.log(e);
            vm.stopLoadingNameForGuid(guid);
          });
      } else if (typeHuman == "Policy") {
        policies
          .getPolicy(this.tenantId, guid)
          .then((r) => {
            vm.names[guid] = r.data["$v"].displayName;
            vm.stopLoadingNameForGuid(guid);
          })
          .catch((e) => {
            console.log(e);
            vm.stopLoadingNameForGuid(guid);
          });
      } else if (typeHuman == "Article") {
        articles
          .getArticle(this.tenantId, guid)
          .then((r) => {
            vm.names[guid] = r.data["$v"].displayName;
            vm.stopLoadingNameForGuid(guid);
          })
          .catch((e) => {
            console.log(e);
            vm.stopLoadingNameForGuid(guid);
          });
      } else if (typeHuman == "External Link") {
        externalLinks
          .getExternalLink(this.tenantId, guid)
          .then((r) => {
            vm.names[guid] = r.data["$v"].displayName;
            vm.stopLoadingNameForGuid(guid);
          })
          .catch((e) => {
            console.log(e);
            vm.stopLoadingNameForGuid(guid);
          });
      } else if (typeHuman == "Topic") {
        topics
          .getTopic(this.tenantId, guid)
          .then((r) => {
            vm.names[guid] = r.data["$v"].displayName;
            vm.stopLoadingNameForGuid(guid);
          })
          .catch((e) => {
            console.log(e);
            vm.stopLoadingNameForGuid(guid);
          });
      } else if (typeHuman == "Guidance Pack") {
        this.names[guid] = "Guidance Pack";
        vm.stopLoadingNameForGuid(guid);
      } else {
        this.names[guid] = "Unknown";
        vm.stopLoadingNameForGuid(guid);
      }
    },
  },
  computed: {
    isLoadingCombined() {
        return (this.isLoading || this.isLoadingNameForGuids.length);
    },

    entityViewObjectTypes() {
      return contentLinkHelpers.listEntityViewObjectTypes();
    },

    contentViewsForTable() {
      let cvs = _.map(this.contentViews, (contentView) => {
        let cv = {
          ...contentView,
        };

        let type = _.find(this.entityViewObjectTypes, {
          value: contentView.viewedEntityType,
        });
        cv.type = type.type;

        let name = this.names[contentView.viewedEntityId];
        if (name) {
          cv.name = name;
        } else {
          cv.name = "Loading...";
        }

        let viewedAtMoment = moment.utc(contentView.viewedAt);
        let viewedAtHuman;
        if (viewedAtMoment.isValid()) {
          viewedAtHuman = viewedAtMoment.format("ddd, Do MMMM YYYY, h:mm");
        } else {
          viewedAtHuman = "Unknown";
        }
        cv.viewedAtHuman = viewedAtHuman;

        let viewSourceHuman;
        viewSourceHuman = cv.viewSource;
        if (viewSourceHuman == 0) {
            viewSourceHuman = 'Unknown';
        }
        if (viewSourceHuman == 1) {
            viewSourceHuman = 'Announcement';
        }
        if (viewSourceHuman == 2) {
            viewSourceHuman = 'Timeline';
        }
        if (viewSourceHuman == 3) {
            viewSourceHuman = 'Browse';
        }
        if (viewSourceHuman == 4) {
            viewSourceHuman = 'Admin Portal';
        }
        cv.viewSourceHuman = viewSourceHuman;

        return cv;
      });

      return _.orderBy(cvs, "viewedAt", "desc");
    },
  },
};
</script>

<style lang="scss">
.user-content-views {
  .loader {
    text-align: center;
    padding: 40px;
  }

  .ant-table {
    background: #fff !important;
  }
}
</style>