<template>
  <div class="manage-users-show">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Loading -->
    <div v-if="isLoading" class="text-center loading-wrapper">
      <a-spin />
    </div>
    <!-- / Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <!-- Page Header -->
      <div class="page-header">
        <h1 class="page-title">
          <a-button type="default" style="display: inline; margin-right: 15px"
            @click.prevent="$router.push('/admin/users/manage')" icon="left"></a-button>

          <presence-thumbnail style="margin-right: 15px" :presence-id="user.id"
            :owner-id="selectedOrganisation.id"></presence-thumbnail>

          <!-- <admin-org-indicator></admin-org-indicator> -->

          {{ title }}
        </h1>
        <div class="actions">
          <a-button class="button-margin-left btn-rounded" icon="edit" size="large" type="primary"
            @click.prevent="edit">Edit</a-button>

          <a-button @click.prevent="attemptDelete" class="button-margin-left btn-rounded" icon="delete" size="large"
            type="danger">Delete</a-button>
        </div>
      </div>
      <!-- / Page Header -->

      <!-- Tabs -->
      <a-tabs v-model="selectedTab">
        <a-tab-pane key="user-details" tab="User Details"></a-tab-pane>
        <a-tab-pane key="content-views" tab="Content Views"></a-tab-pane>
        <a-tab-pane key="response-teams" tab="Response Teams"></a-tab-pane>
        <a-tab-pane key="devices" tab="Devices"></a-tab-pane>
        <!-- <a-tab-pane key="rotas" tab="Rotas"></a-tab-pane> -->
        <a-tab-pane key="availability" tab="Availability"></a-tab-pane>
      </a-tabs>
      <!-- / Tabs -->

      <!-- Content -->
      <div class="content">
        <user-details v-if="selectedTab == 'user-details'" :user="user" :org="selectedOrganisation"
          :tenant-id="tenantId"></user-details>

        <content-views v-if="selectedTab == 'content-views'" :user="user" :org="selectedOrganisation"
          :tenant-id="tenantId">
        </content-views>

        <response-teams v-if="selectedTab == 'response-teams'" :user="user" :org="selectedOrganisation"
          :tenant-id="tenantId"></response-teams>

        <user-devices v-if="selectedTab == 'devices'" :user="user" :org="selectedOrganisation"
          :tenant-id="tenantId"></user-devices>

        <user-availability-manager v-if="selectedTab == 'availability'" :user="user" :org="selectedOrganisation"
          :tenant-id="tenantId"></user-availability-manager>
      </div>
      <!-- / Content -->
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import presences from "../../../../api/presences";
import PresenceThumbnail from "../../../../components/Presences/PresenceThumbnail";
import UserDetails from "./Show/UserDetails.vue";
import UserDevices from "./Show/UserDevices.vue";
import ContentViews from "./Show/ContentViews.vue";
import ResponseTeams from "./Show/ResponseTeams.vue";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import UserAvailabilityManager from "./Show/UserAvailabilityManager.vue";
export default {
  data() {
    return {
      isLoading: false,
      isDeleting: false,
      user: null,
      selectedTab: "user-details",
    };
  },
  components: {
    PresenceThumbnail,
    UserDetails,
    ContentViews,
    ResponseTeams,
    LoadingScreen,
    UserDevices,
    UserAvailabilityManager
  },
  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),

    presenceId() {
      return this.$route.params.id;
    },

    title() {
      if (this.isLoading) {
        return "Loading...";
      }
      if (this.user && this.user.displayName) {
        return this.user.displayName;
      }
      return "Unknown";
    },
  },
  created() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      let vm = this;
      vm.isLoading = true;
      vm.user = null;
      presences
        .getPresence(this.tenantId, this.presenceId)
        .then((r) => {
          vm.isLoading = false;
          vm.user = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading user");
          vm.$router.push("/admin/users/manage");
        });
    },

    edit() {
      this.$router.push('/admin/users/manage/' + this.user.id + '/edit');
    },

    attemptDelete() {
      let vm = this;
      this.$confirm({
        title: "Are you sure you want to delete this user?",
        content:
          "This user will be able to log into any other organisations they have access to.",
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        onOk() {
          vm.delete();
        },
        onCancel() { },
      });
    },

    delete() {
      let vm = this;
      vm.isDeleting = true;
      presences
        .deletePresence(vm.tenantId, vm.user.id)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("User deleted successfully");
          vm.$router.push("/admin/users/manage");
        })
        .catch((e) => {
          vm.isDeleting = false;
          console.log(e);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.manage-users-show {
  padding-bottom: 30px;
}

.button-margin-left {
  margin-left: 10px;
}
</style>